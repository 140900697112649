.icons {
  padding: 1rem;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}