:root {
  --text-color: #16171a;
  --border-color: #cacfd9;
  --action-color: #00487f;
  --scrollbar-color: #d9d9d9;

  --very-small-spacing: 4px;
  --small-spacing: 8px;
  --medium-spacing: 16px;
  --medium-large-spacing: 20px;

  --thumb-height: 85px;
  --thumb-width: 110px;
  --scrollbar-height: 5px;
  --mask-width: 50px;

  --small-text-size: 12px;
}

.vehicle-media-upload-link {
  display: inline;
  cursor: pointer;
  color: var(--action-color);
}

.vehicle-media-upload-link .upload-link-text {
  margin-left: var(--very-small-spacing);
}

.vehicle-media .info-section-container {
  margin-top: var(--medium-spacing);
}

.vehicle-media .info-section-container .info-section {
  font-size: var(--small-text-size);
  display: flex;
  align-items: center;
  gap: var(--small-spacing);
}

.vehicle-media .hd-buttons-container {
  margin-left: auto;
}

.hd-vdp-media-button-overlay,
.hd-vdp-media-cc-button-overlay {
  right: var(--medium-large-spacing);
  margin: var(--very-small-spacing);
  font-size: var(--small-text-size);
  padding: 2px var(--medium-spacing);
}

.vehicle-media {
  overflow: hidden;
  position: relative;
  margin-top: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  align-items: center;
}

.vehicle-media .current-asset {
  display: flex;
  flex-wrap: wrap;
}

.vehicle-media .current-asset nsemble-button span {
  text-transform: capitalize;
  margin-left: var(--very-small-spacing);
}

.vehicle-media .current-asset img {
  width: 100%;
  object-fit: scale-down;
}

.vehicle-media .thumb-container {
  position: relative;
  width: 100%;
  height: min-content;
  text-align: center;
  overflow: hidden;
}

@media (hover: none) {
  .vehicle-media .current-asset img,
  .vehicle-media .thumb-container {
    pointer-events: none;
  }
}

.vehicle-media .nav-area {
  display: block;
  position: relative;
  margin-left: var(--medium-large-spacing);
}

.vehicle-media .thumb-container .icon-overlay {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.75;
}

.vehicle-media video {
  width: 100%;
  height: 100%;
  margin: auto auto;
}

.vehicle-media ul {
  list-style: none;
  display: flex;
  overflow-x: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

.vehicle-media ul li {
  box-sizing: border-box;
  margin: 4px 6px;
  position: relative;
}

.vehicle-media ul li > nsemble-button {
  padding: 0px;
  margin: 0;
  border: solid 2px var(--border-color);
  border-radius: 5px;
  box-shadow: 3px 3px 4px 2px transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.vehicle-media ul li img {
  min-height: 24px;
  max-height: var(--thumb-height);
  width: var(--thumb-width);
  max-width: var(--thumb-width);
  object-fit: contain;
  object-position: center;
}

.vehicle-media .icon-line {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.vehicle-media .icon-line:not(:first-child) {
  margin-top: var(--medium-spacing);
}

.vehicle-media .icon-line .asset-group {
  display: flex;
  margin: 0 var(--small-spacing);
}

.vehicle-media .icon-line .asset-group > div {
  margin-left: var(--very-small-spacing);
}

.vehicle-media .icon-line .asset-group > span {
  margin-right: var(--very-small-spacing);
}

.vehicle-media .icon-line svg path,
.vehicle-media .icon-line svg circle,
.vehicle-media .icon-line svg rect {
  stroke: var(--text-color);
}

.vehicle-media .images-nav,
.vehicle-media .videos-nav {
  background-color: transparent;
  padding: 20px 5px;
  border: none;
  display: flex;
  align-items: center;
  min-height: calc(var(--thumb-height) + 47px);
  overflow-y: overlay;
  mask-image: linear-gradient(to right, black calc(100% - var(--mask-width)), transparent),
    linear-gradient(to top, black 20px, transparent 10px);

  padding-right: var(--mask-width);
}

.vehicle-media .images-nav .active,
.vehicle-media .videos-nav .active {
  border: 1.5px solid #25308f;
  border-radius: 5px;
  background-color: var(--border-color);
}

.vehicle-media ::-webkit-scrollbar,
.vehicle-media ::-webkit-scrollbar-thumb,
.vehicle-media ::-webkit-scrollbar-track {
  height: var(--scrollbar-height);
  border: none;
  background: transparent;
}

.vehicle-media ::-webkit-scrollbar-button,
.vehicle-media ::-webkit-scrollbar-track-piece,
.vehicle-media ::-webkit-scrollbar-corner,
.vehicle-media ::-webkit-resizer {
  display: none;
}

.vehicle-media ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--scrollbar-color);
}

@media (max-width: 768px) {
  .vehicle-media {
    display: block;
  }
  .vehicle-media .current-asset {
    display: flex;
    justify-content: space-evenly;
  }
  .vehicle-media .nav-area {
    margin-left: 0;
  }
  .vehicle-media .icon-line {
    margin-top: var(--medium-spacing);
  }
}
