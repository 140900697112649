.tooltip-trigger-text {
  border-bottom: 1px solid #00528A;
  color: #00528A;
  cursor: pointer;
}

.tooltip-body {
  position: fixed;
  padding: 10px;
  background: #1F2C3A;
  border-radius: 4px;
  color: white;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  font-family: 'Roboto';
  transform: translateX(-50%);
}

.tooltip-body:after {
  content: " ";
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(31, 44, 58, 0);
  border-width: 8px;
}

.tooltip-top:after {
  top: 100%;
  left: 50%;
  border-top-color: #1F2C3A;
  margin-left: -8px;
}

.tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  border-bottom-color: #1F2C3A;
  margin-left: -8px;
}

.tooltip-left:after {
  top: 30%;
  left: 106%;
  transform: rotate(90deg);
  border-bottom-color: #1F2C3A;
  margin-left: -8px;
}

.tooltip-right:after {
  top: 30%;
  right: 100%;
  transform: rotate(270deg);
  border-bottom-color: #1F2C3A;
  margin-left: -8px;
}
