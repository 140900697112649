/* .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
} */

.sidebar {
    margin-top: 50px;
    padding: 10px;
}

.sidebar  a {
    color: #1a1a1a!important;
    text-decoration: none;
}

.active-link {
    font-weight: 700;
    font-style: italic;
}