.upload-code-block {
    display: inline-block;
    background-color: lightgrey;
    border: 1px solid gray;
    padding: 15px;
    max-width: 100%;
    white-space: break-spaces;
}

.upload-info-block {
    padding: 15px;
    float: right;
}