.modal-container {
    width: fit-content;
    z-index: 200;
}

.vehicle-image-spinner {
    width: 80vw;
    height: 90vh;
    max-width: 800px;
    max-height: 600px;
    display: grid;
    justify-items: center;
    align-items: center;
}

.no-display {
    display: none;
}

.sortable-save-container {
    margin: 10px;
}

.sortable-save-alert-container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.sortable-save-msg {
    grid-column-start: 1;
    grid-column-end: span 1;
    padding-right: 10px;
}

.sortable-save-button {
    grid-column-start: 2;
    grid-column-end: span 1;
    border-radius: 8px;
    padding: 0 16px;
    height: 36px;
    font-size: 16px;
    margin: 5px 0;
}

@media (max-width: 688px) {
    .sortable-save-alert-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }

    .sortable-save-msg {
        grid-column-start: 1;
        grid-column-end: span 1;
        grid-row-start: 1;
        grid-row-end: span 1;
        padding-right: 0;
        padding-bottom: 10px;
    }

    .sortable-save-button {
        grid-column-start: 1;
        grid-column-end: span 1;
        grid-row-start: 2;
        grid-row-end: span 1;
    }
}

.sortable-save-button:hover {
    cursor: pointer;
}

.sortable-save-button:active {
    opacity: 0.50;
}

.sortable-save-button:disabled {
    opacity:0.30;
    cursor: not-allowed;
}

.sortable-delete-image-modal {
    width: 46vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    z-index: 2;
    background-color: white;
    border-radius: 4px;
}

.sortable-delete-button-list {
    align-self: flex-end;
}

.sortable-delete-x-button {
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.sortable-delete-cancel-button {
    margin-right: 10px;
    border-radius: 3px;
    padding: 2px 16px;
}

.sortable-delete-button {
    border-radius: 3px;
    padding: 2px 16px;
}

.sortable-btn-primary {
    background: transparent;
    border: 1px solid rgb(0, 82, 138);
    background-color: rgb(0, 82, 138);
    color: #FFFFFF;
}

.sortable-btn-secondary {
    background: transparent;
    border: 1px solid rgb(0, 82, 138);
    background-color: #FFFFFF;
    color: rgb(0, 82, 138);
}

.sortable-btn:active {
    opacity: 0.50;
}

.sortable-btn:disabled {
    opacity:0.30;
    cursor: not-allowed;
}