.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-container .pagination-item {
  color: #00528a;
  display: flex;
  border: 1px solid transparent;
  border-radius: 2px;
  line-height: 1.43;
  height: 24px;
  min-width: 24px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.pagination-container .pagination-item.truncation:hover {
  background-color: transparent;
  border: 1px solid transparent;
  cursor: default;
}

.pagination-container .pagination-item:hover {
  text-decoration: none;
  border: 1px solid #00528a;
  box-shadow: none;
  cursor: pointer;
}

.pagination-container .pagination-item.selected {
  background-color: #00528a;
  color: #ffffff;
}

.pagination-container .pagination-item.disabled {
  pointer-events: none;
}

.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-container svg {
  cursor: pointer;
}

.pagination-container .pagination-arrow:hover {
  background-color: transparent;
  border: 1px solid transparent;
}

.pagination-container .pagination-arrow.disabled {
  opacity: 0.2;
}
