.footer-container {
  background-color: #e5e5e3;
  left: 20px;
  bottom: 0;
  font-size: 12px;
  padding: 5px;
}

.footer-content {
  text-align: center;
}

.footer-container a {
  color: #369;
}

.footer-container a:hover {
  text-decoration: none;
}

.footer-links-separator {
  margin: 0px 0.3vw;
}

.footer-row-margin {
  margin-right: 0.3vw;
}
