.alerts-container {
  display: grid;
  position: relative;
  border-radius: 3px;
}

.alerts-dismissible {
  grid-template-columns: 1fr 50px;
}

.alerts-body {
  grid-column-start: 1;
  grid-column-end: span 1;
  padding: 1rem 1rem;
}

.alerts-close {
  display: grid;
  align-items: center;
  background: transparent;
  border: none;
  grid-column-start: 2;
  grid-column-end: span 1;
  padding: 1rem 1rem;
}

/* success */

.alerts--success {
  color: rgb(97, 177, 122);
  background-color: rgba(97, 177, 122, 0.2);
}

.alerts-close--success {
  border-left: 1px solid rgba(97, 177, 122, 0.5);
  color: rgb(97, 177, 122);
}

.alerts-close--success:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* error */

.alerts--error {
  color: rgb(204, 67, 49);
  background-color: rgba(204, 67, 49, 0.2);
}

.alerts-close--error {
  border-left: 1px solid rgba(204, 67, 49, 0.5);
  color: rgb(204, 67, 49);
}

.alerts-close--error:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* warning */

.alerts--warning {
  color: rgb(236, 157, 54);
  background-color: rgba(236, 157, 54, 0.2);
}

.alerts-close--warning {
  border-left: 1px solid rgba(236, 157, 54, 0.5);
  color: rgb(236, 157, 54);
}

.alerts-close--warning:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* message */

.alerts--message {
  color: rgb(0, 72, 127);
  background-color: rgba(0, 72, 127, 0.08);
}

.alerts-close--message {
  border-left: 1px solid rgba(0, 72, 127, 0.5);
  color: rgb(0, 72, 127);
}

.alerts-close--message:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
