.example-table-row {
  padding: 5px 15px;
  border-bottom: 1px solid #cacfd9;
  text-align: center;
}

.example-table-toggle {
  display: table-cell;
  vertical-align: middle;
}

.example-table-row img {
  height: 75px;
}
