.dropzone-container {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  border: 3px dashed #CACFD9;
  border-radius: 4px;
  cursor: pointer;
}

.dropzone-container--maxed {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  border: 3px dashed #EC9D36;
  border-radius: 4px;
  cursor: pointer;
}

.dropzone-content {
  padding: 15px;
}

.dropzone-upload-icon-container {
  padding: 15px;
  display: flex;
  justify-content: center;
}

.dropzone-icon-background {
  display: inline-block;
  width: 80px;
  height: 80px;
  padding: 20px;
  text-align: center;
  border-radius: 50%; 
  background: rgba(0, 82, 138, 0.1);
}

.dropzone-icon-background--maxed {
  display: inline-block;
  width: 80px;
  height: 80px;
  padding: 20px;
  text-align: center;
  border-radius: 50%; 
  background: rgba(236, 157, 54, 0.3);
}

.dropzone-upload-icon {
  color: #00528A;
}

.dropzone-upload-icon--maxed {
  color: #EC9D36;
}

.dropzone-icon-background--success {
  display: inline-block;
  width: 80px;
  height: 80px;
  padding: 20px;
  text-align: center;
  border-radius: 50%; 
  background: rgba(97, 177, 122, 0.2);
}

.dropzone-upload-icon--success {
  color: #61B17A;
  transform: translateY(3px);
}

.dropzone-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  text-align: center;
}

.dropzone-heading--success {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  text-align: center;
  color: #61B17A;
}

.dropzone-subheading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #808285; 
}

.dropzone-modal-button-container {
  align-self: flex-end;
}

.dropzone-modal-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.dropzone-modal-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.dropzone-cancel-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.dropzone-modal-button {
  background-color: #00538a;
  border: none;
  color: white;
  border-radius: 3px;
  padding: 10px 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.dropzone-spinner {
  margin: 15px auto;
  height: 80px;
  width: 80px;
}

.dropzone-multierror-container {
  padding-top: 5px;
}

.dropzone-multierror-item {
  padding: 2px;
}