:root {
    --color-blue: #369;
    --color-white: #FFF;
    --color-black: #000;
    --color-light-gray: #CCC;
    --color-dark-gray: #959595;
}

.side-nav-bar-container {
    width: 220px;
    font-size: 12px;
    padding: 5px;
    background-color: var(--color-light-gray);
    border: 1px solid var(--color-dark-gray);
    height: fit-content;
}

@media(max-width: 768px) {
    .side-nav-bar-container {
        width: 170px;
    }
}

.side-nav-bar-wrapper {
    background-color: var(--color-white);
    border: 1px solid #b0b0b0;
}

.side-nav-bar-content {
    padding: 8px 4px;
}

.side-nav-bar-content:hover {
    cursor: pointer;
}

.side-nav-section {
    padding-bottom: 5px;
}

.side-nav-title {
    font-weight: 700;
}

.side-nav-title-hr {
    margin: 1vh 0 0 0;
    background: var(--color-dark-gray);
}

.side-nav-bar-link-wrapper {
    display: grid;
    min-height: 28px;
}

.side-nav-bar-link-wrapper a {
    color: var(--color-blue);;
    text-decoration: none;
    display: grid;
    align-content: center;
    padding-left: 13px;
}

.side-nav-bar-link-wrapper:hover a {
    color: var(--color-blue);
    text-decoration: underline;
}

.side-nav-bar-link-wrapper.side-nav-links-active {
    background-color: var(--color-blue);;
}

.side-nav-bar-link-wrapper.side-nav-links-active a {
    color: var(--color-white);
    font-weight: 700;
}

.side-nav-bar-link-wrapper.side-nav-links-active:hover a {
    color: var(--color-white);
    font-weight: 700;
    text-decoration: none;
}

.inherited-link {
    width: 100%;
    height: 100%;
}