.radioButtonInputGroupContainer {
  display: flex;
  gap: 2rem;
}
.radioButtonInput {
  display: flex;

  nsemble-radio-button {
    padding: 0;
  }

  nsemble-button {
    display: none;
  }

  nsemble-input {
    padding: 6px 0px;
  }

  .radioButtonInputFormInput {
    padding-left: 10px;
  }
}

.radioButtonInputLabel {
  margin-bottom: -8px;
}
