.nav-item-container {
  padding: 10px 10px 10px 10px;
  margin-left: 10px;
}

.nav-item-container:hover .nav-dropdown-container {
  clip: auto;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}

.nav-item-dropdown:hover {
  background-color: #000000;
}

.nav-item-container .dropdown-icon {
  font-size: 10px;
  font-weight: 900;
  padding-left: 5px;
}

.nav-item-container a,
.nav-item-container > * {
  color: #ffffff;
  text-decoration: inherit;
  display: block;
}

.nav-item-container a:hover,
.nav-item-container > *:hover {
  color: #6d7278;
}

@container (max-width: 1100px) {
  .nav-item-container {
    background-color: #ffffff;
    padding: 0;
    margin-left: 0;
    white-space: nowrap;
  }

  .nav-item-container:hover .nav-dropdown-container {
    position: relative;
  }
  .nav-item-container .dropdown-icon {
    font-size: 16px;
  }

  .nav-item-dropdown:hover {
    background-color: #ffffff;
  }

  .nav-item-container a,
  .nav-item-container > * {
    color: #000000;
    margin-top: 30px;
    margin-left: 20px;
  }
}
