.sortable-image-container {
  position: relative;
}

.sortable-image-container .sortable-image-item {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.sortable-image-container .no-overlay {
  display: none;
}

.sortable-image-container .icon-overlay {
  position: absolute;
  height: 60px;
  width: 100%;
  z-index: 101;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  pointer-events: none;
}

.sortable-image-container .sortable-image-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 100;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 2fr;
  justify-items: center;
  align-items: center;
  pointer-events: none;
}

.sortable-image-container .sortable-image-invalid {
  background-color: grey;
}

.sortable-image-container .sortable-zoom-icon {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  color: white;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: auto;
}

.sortable-image-container .sortable-zoom-icon:hover {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  color: white;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  font-size: 32px;
}

.sortable-image-container .sortable-delete-icon {
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  color: white;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: auto;
}

.sortable-image-container .sortable-delete-icon:hover {
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  color: white;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  font-size: 32px;
}

.sortable-image-container .sortable-drag-icon {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: 2;
  grid-row-end: span 2;
  color: white;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 64px;
}
