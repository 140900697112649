.edit-popup-text {
  color: #00528a;
  cursor: pointer;
}

.edit-popup-body {
  position: fixed;
  padding: 5px 0 5px 20px;
  background: #1f2c3a;
  border-radius: 4px;
  background-color: #fff;
  line-height: 20px;
  text-align: center;
  color: #636363;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.25);
  transition: top 0.25s;
  animation: popup-fade-in 0.35s;
  z-index: 500;
}

.edit-popup-body .label {
  margin-right: 16px;
  flex: 1.4;
}

.edit-popup-body .label .required {
  color: #cc4331;
}

.edit-popup-body nsemble-input {
  width: 100%;
}

.edit-popup-body .errorText {
  position: absolute;
  margin-top: 17px;
}

.edit-popup-body svg {
  vertical-align: middle;
}

.edit-popup-body .close-button svg {
  fill: #00528a;
}

.edit-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 499;
  animation: background-fade-in 0.5s;
  background-color: #000000bf;
}

@keyframes background-fade-in {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

@keyframes popup-fade-in {
  0% {
    opacity: 0;
    transform: scale(05);
  }
  35% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
