.image-grid-item {
    object-fit: cover;
    height: 200px;
    width: 100%;
    cursor: pointer;
}

.image-grid-modal-spinner {
    width: 80vw;
    height: 90vh;
    max-width: 800px;
    max-height: 600px;
    display: grid;
    justify-items: center;
    align-items: center;
}

.no-display {
    display: none;
}