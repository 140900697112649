.tabs-container .nav-tabs .nav-link {
  border: none;
  margin-bottom: 0;
  color: #6D727A;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  outline: none;
  background: none;

  &:hover {
    color: #16171A;
  }

  &.active {
    color: #16171A;
    border-bottom: 2px solid #003469;
  }
}
