.toggle-switch {
  position: relative;
  display: block;
  width: 60px;
  height: 34px;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 34px;
  margin: 0;
}

.toggle-switch-inner {
  display: block;
  position: absolute;
  width: 40px;
  transition: margin 0.3s ease-in 0s;
  background-color: #fff;
  height: 15px;
  border-radius: 34px;
  top: 10px;
  left: 10px;
  border: 1px solid #cacfd9;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  background-color: #00528a;
}

.toggle-switch-switch {
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  left: 30px;
}

.toggle-switch-children {
  position: absolute;
  left: 65px;
  top: 5px;
  display: flex;
}
