.open-modal-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-button-container {
  display: flex;
  justify-content: flex-end;
}

.modal-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.modal-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
