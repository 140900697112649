.nav-dropdown-container {
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0.1;
  transition: transform 0.3s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(-10px) scaleY(0.5);
  transform-origin: top;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 10em;
  background-color: #000000;
  margin-left: -10px;
  box-shadow: inset 0 30px 15px -35px rgba(0, 0, 0, 0.5);
}

@container (max-width: 1100px) {
  .nav-dropdown-container {
    top: 0;
    background-color: #cacfd9;
    width: 100%;
    margin-left: 0;
  }

  .nav-dropdown-container > * {
    margin-top: 30px;
    margin-left: 20px;
  }
}
