.navbar-container {
  width: 100%;
  background-color: #012169;
  display: grid;
  container-type: inline-size;
}

.navbar-container .navbar-content {
  display: grid;
  position: relative;
  grid-template-columns: 250px 1fr;
  justify-self: center;
}

.navbar-container .logo {
  display: grid;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.navbar-container .logo img {
  height: 35px;
}

.navbar-container .menu-icon {
  display: none;
  color: #ffffff;
  justify-self: right;
  padding: 15px;
}

.navbar-container .nav-elements {
  display: grid;
  grid-auto-flow: column;
  list-style-type: none;
  justify-content: left;
  font-size: 1vw;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  align-items: end;
}

.navbar-container .nav-elements a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.navbar-container .nav-elements a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@container (max-width: 1500px) {
  .navbar-container .navbar-content {
    grid-template-columns: 150px 1fr;
  }
}

@container (max-width: 1100px) {
  .navbar-container .navbar-content {
    grid-template-columns: 150px auto;
    align-content: center;
    justify-self: normal;
  }

  .navbar-container .logo {
    padding-left: 15px;
  }

  .navbar-container .nav-elements li:not(:last-child) {
    margin-right: 30px;
  }

  .navbar-container .menu-icon {
    display: block;
    cursor: pointer;
  }

  .navbar-container .nav-elements {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: #ffffff;
    color: #000000;
    box-shadow: none;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    align-items: normal;
    font-size: 14px;
  }

  .navbar-container .nav-elements.active {
    width: 270px;
    box-shadow: 15px 0px 20px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }

  .navbar-container .nav-elements {
    display: flex;
    flex-direction: column;
  }
}

@container (max-width:480px) {
  .navbar-container .nav-elements div {
    margin-top: 5px;
  }
}
