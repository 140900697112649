.logout-user{
    text-align: center;
}

.logout-user span {
    font-size: 25px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.logout-user span:nth-child(2) {
    animation-delay: .2s;
}

.logout-user span:nth-child(3) {
    animation-delay: .4s;
}

@keyframes blink {
    0% {
      opacity: .2;
    }
    
    20% {
      opacity: 1;
    }
    
    100% {
      opacity: .2;
    }
}

.user-details-container {
    background-color: #e5e5e3;
    font-size: 0.9vw;
}

.header-user-info {
    width: 85%;
    justify-content: flex-end;
}

.header-user-info a {
    color:#369;        
}

.header-user-info a:hover{
    text-decoration: none;
}

.flex-row-header {
    display: flex;
    flex-direction: row;
}

.header-links {
    display: flex;
    flex-direction: row;
}

.admin-header {
    margin-right: 25%; 
}

.admin-header .remove-user-link {
    margin-left: 4px;
}

.header-user-info-separator {
    margin: 0px 5px;
}

.header-user-info-links-separator {
    margin: 0px 5px;
}

.header-container .container {
    margin-left: 20px;
}

.header-container .mr-auto, .mx-auto {
    width: 100%;
}

.nav-brand-logo {
    margin-left: 0.2vw;
    width: 9vw;
    height: 3vh;
}

.navbar-header-link:first-child {
    margin-left: 4%;
}

.navbar-header-link:last-child {
    margin-left: 1%;
}
    
.header-nav-bar {
    background-color: #012169;
    height: 40px;  
}

.at-logo-image {
    margin-left: 5%;
}

.sign-out-container{
    cursor: pointer;
    color:#369; 
}

.header-container .bg-dark {
    background-color: #012169;
    border-color: #012169;
}

.navbar-brand {
    padding: 7px 0px 0px 0px;
}

.nav > li {
    margin-top: 7px;
}

.header-container .navbar-dark .navbar-nav .nav-link {
    color: #FFFFFF;
    font-size: 0.9vw;
}

.header-container .navbar-expand-lg .navbar-nav .dropdown-menu.show{ 
    background-color: #080808;
} 

.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-item { 
    color: #FFFFFF;
    font-size: 0.9vw;
}

.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-item:hover{ 
    background-color: #080808;
}

.dropdown-menu > li > a {
    color: #FFFFFF;
}

.dropdown-toggle::after {        
    vertical-align: 0em;
}

@media(max-width: 1440px) {
    .user-details-container {
        font-size: 1vw;
    }

    .header-container .nav-elements {
        font-size: 14px;
    }
}

@media(max-width: 1100px) {

    .user-details-container {
        font-size: 1.1vw;
    }

    .header-container .nav-elements {
        font-size: 14px;
    }
}

@media(max-width: 480px) {

    .header-container .nav-elements {
        font-size: 12px;
    }
}
  
