.ds-card {
  border-radius: 4px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  flex-grow: 1;
  padding-bottom: 16px;
  margin-top: 16px;
}

.ds-card .card-title {
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  padding: 16px 16px;
}

.ds-card .card-content {
  padding: 0 20px;
}

.ds-card:not(:first-child) {
  margin-top: 16px;
}
