.sort-select-container {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  justify-content: flex-end;
}

.sort-by-label {
  padding-right: 5px;
}

.sort-select-box {
  width: max-content;
}

.sort-select-toggle nsemble-button {
  padding: 0 8px;
}
