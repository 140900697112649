.container {
  border-radius: 4px;
  box-shadow: 0 1px 4px 1px #00000026;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.headerBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  color: #00528A;
}

.icon {
  transition: .5s ease;
}

.text {
  margin-top: 1rem;
  transition: .5s ease;
}

.rotate {
  transform: rotate(180deg);
}

.hidden {
  visibility: hidden;
  overflow: hidden;
  height: 0px;
  margin-top: 0px;
}
