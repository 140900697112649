body {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
}

.application-container {
  display: grid;
  min-height: 100vh;
  grid-template-columns: auto 1fr;
}

.sidebar-container {
  white-space: nowrap;
  background-color: #e2e1e1;
  min-width: 200px;
}

.page-container {
  margin-left: 5vw;
  width: 70vw;
  @media (max-width: 768px) {
    margin-left: 2vw;
    width: 60vw;
  }
}

.page-component-spacing {
  margin-top: 4vh;
}
