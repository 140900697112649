.nav-dropdown-item-container {
  display: block;
  padding: 5px 10px;
}

.nav-dropdown-item-container:first-child {
  padding-top: 10px;
}

@container (max-width: 1100px) {
  .nav-dropdown-item-container {
    padding: 0;
  }

  .nav-dropdown-item-container:last-child {
    padding-bottom: 20px;
  }
}
