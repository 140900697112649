.dropzone {
    width: 100%;
    margin: auto;
    padding: 20px;
}

.no-images-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #808285; 
}