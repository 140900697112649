.imagelist-container {
    margin-top: 15px;
}

.delete-image-modal {
    width: 46vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    z-index: 2;
    background-color: white;
    border-radius: 4px;
}

.imagelist-button-list {
    align-self: flex-end;
}

.imagelist-back-button {
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.imagelist-cancel-button {
    background: transparent;
    border: 1px solid #00538a;
    margin: 0 10px 0 0;
    color: #00538a;
    border-radius: 3px;
    padding: 2px 16px;
}

.imagelist-delete-button {
    background-color: #00538a;
    border: none;
    color: white;
    border-radius: 3px;
    padding: 2px 16px;
}