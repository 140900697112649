.inputContainer {
  display: flex;
  padding: .25rem 0;
  align-items: center;
}

.label {
  flex: 1.4;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.value {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.required {
  color: #CC4331;
}
