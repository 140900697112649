.table-container {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-header {
  padding-bottom: 8px;
  border-bottom: 1px solid #cacfd9;
}

.table-row {
  padding: 20px 15px;
  border-bottom: 1px solid #cacfd9;
}

tr {
  text-align: center;
}
