.breadcrumb {
 list-style-type: none;   
 display: flex;
 flex-wrap: wrap;
 width: 40vw;
 padding: 0;
 background-color: transparent;
}

.breadcrumb > *::after {
    content: '\00a0\003E\00a0';
}

.breadcrumb > *:last-child a {
    color: black;
}

.breadcrumb > *:last-child::after {
    content: '';
}

@media(max-width: 1440px) {
    .breadcrumb {
        width: 50vw;
    }
}
  
@media(max-width: 992px) {
    .breadcrumb {
        width: 60vw;
    }
}
  
@media(max-width: 768px) {
    .breadcrumb {
        width: 70vw;
    }
}