.modalBackdrop {
  position: fixed;
  z-index: 100;
  inset: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}

.modalContainer {
  z-index: 200;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 4px;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.body {
  padding: 0.5rem 0;
}

.closeIcon {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.5rem;
  margin: 0;

  &:hover {
    background: #0000000a;
    transition: 0.5s ease;
    border-radius: 50%;
  }
}
