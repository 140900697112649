:root {
  --text-color: #16171a;
  --border-color: #cacfd9;
  --action-color: #00487f;
  --scrollbar-color: #d9d9d9;
  --background-color: #f8f8f8;
  --white-color: #ffffff;

  --very-small-spacing: 4px;
  --small-spacing: 8px;
  --medium-spacing: 16px;
  --medium-large-spacing: 20px;

  --thumb-height: 85px;
  --thumb-width: 110px;
  --scrollbar-height: 5px;
  --mask-width: 50px;
  --table-cell-min-width: 86px;

  --small-text-size: 12px;
}

.sortable-table {
  font-family: 'Roboto', sans-serif;
}

table.sortable-table-container {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;
}

th.sortable-table-title {
  padding: var(--small-spacing) var(--medium-spacing);
  border: solid 1px var(--border-color);
  text-align: left;
  background-color: var(--background-color);
}

td.sortable-table-cell {
  padding: var(--small-spacing) var(--medium-spacing);
  border: solid 1px var(--border-color);
  text-align: left;
  background-color: var(--white-color);
}

th.sortable-table-title:first-child,
td.sortable-table-cell:first-child {
  border-radius: 4px 0 0 4px;
}

th.sortable-table-title:last-child,
td.sortable-table-cell:last-child {
  border-radius: 0 4px 4px 0;
}

th.sortable-table-title:not(:first-child),
td.sortable-table-cell:not(:first-child) {
  border-left: none;
}

th.sortable-table-title:not(:last-child),
td.sortable-table-cell:not(:last-child) {
  border-right: none;
}

.sortable-table-container {
  width: 100%;
  overflow-x: auto;
}

.sortable-bolded-column {
  font-weight: 800;
}
