.thumbnail-item {
    display: flex;
    height: 120px;
    align-items: flex-end;
    margin: 0 0 30px 0;
}

.button-list {
    display: flex;
    flex-direction: column;
    margin: 0 10px 0 0;
}

.button {
    border: none;
    width: 30px;
    height: 30px;
    margin: 5px 0 0 0;
    border-radius: 50%;
    background: #a8aaac;
}

.background-delete {
    background: #cc4331;
}

.thumbnail-image-container {
    position: relative;
    width: 160px;
    height: 100%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.thumbnail-image-mask {
    opacity: 70%;
}

.thumbnail-image {
    object-fit: cover;
    height: 120px;
    width: 100%;
}

.file-name {
    margin: 0 0 0 20px;
}

.delete-icon {
    color: #fff;
}

.arrow-icon {
    color: #fff;
    transform: translateY(1.5px);
}

.thumbnail-spinner {
    position: absolute; 
}